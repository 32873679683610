import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
const BarChart = ({ data }) => {
    const chartData = {
        labels: ['0 - 10', '10.01 - 20', '20.01 - 30', '30.01 - 50', '50.01 - 70', '70.01 - 100', ' Above 100'],
        datasets: [
            {
                label: 'No of Items vs Price Ranges',
                data: [data.zeroTo10, data.elevenTo20, data.twentyOneTo30, data.thirtyOneTo50
                    , data.fivtyOneTo70, data.seventyOneTo100, data.above100]
                    ,backgroundColor: [
                    'rgba(75, 192, 192, 0.2)'
                ],
                borderColor: [
                    'rgba(75, 192, 192, 1)'
                 ]
                ,borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: 'Items Published Statuses',
            },
        },
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    return <Bar data={chartData} options={options} />;
};

export default BarChart;
