const downloadSamplePartsList = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_API + '/DownloadSampleFile/DownloadSamplePartList', {
        method: 'GET',
      });
      
      if (!response.ok) {
        throw new Error('Failed to download file');
      }
      const blob = await response.blob();
      return blob;
    } catch (error) {
      throw new Error('Error downloading file: ' + error.message);
    }
  };
  export { downloadSamplePartsList };