import React, { useEffect, useState } from 'react';
import { itemsService } from '../services';
import Loader from './Loader';
import { Form, Tab, Tabs } from 'react-bootstrap';
import MaterialTable from '@material-table/core';
import { tableIcons, constants } from '../helpers';
import { ExportCsv, ExportPdf } from '@material-table/exporters';
import { useParams, useLocation } from 'react-router-dom';
import '../App.css';
import BarChart from './BarChart';
const exportFileName = "listings".concat(" ", new Date().getDate(), new Date().getMonth());
export default function ListingLists({ teraPeakTabsSummaryID, partName }) {
    const [pageAtiveData, setActiveData] = useState([]);
    const [pageSoldData, setSoldData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { TabsSummaryID } = useParams();
    const [activeChartData, setactiveChartData] = useState([]);
    const [soldChartData, setSoldChartData] = useState([]);
    const query = new URLSearchParams(useLocation().search);
    partName = query.get('part');
    useEffect(() => {
        setIsLoading(true);
        itemsService.getListingLists(TabsSummaryID, 'active')
          .then(data => {
            setActiveData(data.results);
            setactiveChartData(data.chartData);
          });
          itemsService.getListingLists(TabsSummaryID, 'sold')
          .then(data => {
            setSoldData(data.results);
            setSoldChartData(data.chartData);
            setIsLoading(false);
          });
      }, [TabsSummaryID, teraPeakTabsSummaryID, partName]);

      const generateColumns = (data) => {
        if (data.length === 0) {
            return [];
        }
        const keys = Object.keys(data[0]);
        return keys.map((key) => ({
            title: key,
            field: key,
            render: rowData => {
                if (key === 'Image') {
                    return <img src={rowData[key]} alt="Thumbnail" style={{ width: 100, height: 100 }} />;
                }
                else if(key === 'eBay Item ID') {
                    const url = process.env.REACT_APP_EBAY_ITEM_URL + rowData[key];
                    return <a href={url} target='_blank' rel='noreferrer'>{rowData[key]}</a>;
                }
                else {
                    return rowData[key];
                }
            }
        }));
    };
  return (
    <div>
        <h2 style={{ marginBottom: '15px', textAlign: 'center' }}>
          {partName}
        </h2>
    {isLoading? <Loader /> : 
    <Form>
        <Tabs defaultActiveKey="active" id="listingTabs" className="mb-3">
            <Tab eventKey="active" title="Active">
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px', width: '600px', margin: '0 auto' }}>
                <BarChart data={activeChartData} />
              </div>
                <MaterialTable
                columns={generateColumns(pageAtiveData)} // Dynamic columns
                data={pageAtiveData}
                icons={tableIcons}
                options={{
                    showTitle: false,
                    pageSizeOptions: constants.gridPageSizeOptions,
                    pageSize: constants.gridPageSize,
                    emptyRowsWhenPaging: false,
                    paginationType: 'normal',
                    showFirstLastPageButtons: false,
                    sorting: true,
                    search: true,
                    headerStyle: {
                        backgroundColor: '#ff4500',
                        color: '#FFF',
                        whiteSpace: 'nowrap',
                        position: 'sticky',
                        top: 0,
                        fontSize: '12px',
                        zIndex: 1
                    },
                    rowStyle:{
                        fontSize: '12px'
                    },
                    maxBodyHeight: '400px',
                    exportMenu: [
                        {
                            label: 'Export PDF',
                            exportFunc: (cols, datas) => ExportPdf(cols, datas, exportFileName)
                        }, {
                            label: 'Export CSV',
                            exportFunc: (cols, datas) => ExportCsv(cols, datas, exportFileName)
                        }]
                }}/>
            </Tab>
            <Tab eventKey="sold" title="Sold">
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px', width: '600px', margin: '0 auto' }}>
                <BarChart data={soldChartData} />
              </div>
            <MaterialTable
                columns={generateColumns(pageSoldData)} // Dynamic columns
                data={pageSoldData}
                icons={tableIcons}
                options={{
                    showTitle: false,
                    pageSizeOptions: constants.gridPageSizeOptions,
                    pageSize: constants.gridPageSize,
                    emptyRowsWhenPaging: false,
                    paginationType: 'normal',
                    showFirstLastPageButtons: false,
                    sorting: true,
                    search: true,
                    headerStyle: {
                        backgroundColor: '#ff4500',
                        color: '#FFF',
                        whiteSpace: 'nowrap',
                        position: 'sticky',
                        top: 0,
                        fontSize: '12px',
                        zIndex: 1
                    },
                    rowStyle:{
                        fontSize: '12px'
                    },
                    maxBodyHeight: '400px',
                    exportMenu: [
                        {
                            label: 'Export PDF',
                            exportFunc: (cols, datas) => ExportPdf(cols, datas, exportFileName)
                        }, {
                            label: 'Export CSV',
                            exportFunc: (cols, datas) => ExportCsv(cols, datas, exportFileName)
                        }]
                }}/>          
            </Tab>
        </Tabs>
     </Form>
    }
    </div>)
}