import { authHeader, handleResponse } from '../helpers';
export const setupsService = {
  getConditionsList,
  getDurationList
};
function getConditionsList(showOnlyActive, conditionName) {
    const requestOptions = { 
        method: 'GET', 
        headers: authHeader()
    };
    return fetch(process.env.REACT_APP_API + "/Setups/getconditionslist?ShowOnlyActive="+showOnlyActive +"&ConditionName=" + conditionName, requestOptions)
    .then(handleResponse);
  }
  function getDurationList(showOnlyActive, durationName) {
    const requestOptions = { 
        method: 'GET', 
        headers: authHeader()
    };
    return fetch(process.env.REACT_APP_API + "/Setups/getdurationlist?ShowOnlyActive="+showOnlyActive 
      +"&DurationName=" + durationName, requestOptions)
    .then(handleResponse);
  }