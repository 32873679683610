import React,{Component} from 'react';
import { Navigate } from 'react-router-dom';
import { authenticationService } from '../services';
import Loader from './Loader';
export default class Login extends Component{
  constructor(props){
    super(props);
    this.state={
      loading:true,
      userName: null,
      password: null
    };
    this.handleChange=this.handleChange.bind(this);
    this.handleSubmit=this.handleSubmit.bind(this);
    }
    handleChange(changeObject) {
      this.setState(changeObject);
    }
    handleSubmit(event) {
      event.preventDefault();
      this.setState({loading: true});
      authenticationService.login(this.state.userName, this.state.password)
      .then(
        user => {
            this.setState({loading: false});
            this.setState({authenticated: true});
        },
        error => {
          this.setState({loading: false});
          this.setState({authenticated: false});
          alert(error);
        }
    );
    }
    componentDidMount(){
      this.setState({loading: false});
    }
    render(){
      if(this.state.loading)
      {
        return ( <Loader /> );
      }
      else if (this.state.authenticated) {
       return(<Navigate to="/Home" />);
      }
      else 
      {
        return(
          <div id="loginform" className="auth-wrapper auth-inner shadow-lg p-3 bg-white rounded">
            <h2 id="headerTitle">Login</h2>
              <div>
                <form onSubmit={this.handleSubmit}>
                  <div className="row">
                    <label>Username</label>
                    <input type="text" placeholder="Enter your username" required autoFocus onChange={(e) => this.handleChange({ userName: e.target.value })}/>
                  </div>
                  <div className="row">
                    <label>Password</label>
                    <input type="password" placeholder="Enter your password" required onChange={(e) => this.handleChange({ password: e.target.value })}/>
                  </div>
                  <div id="button" className="row">
                    <button type="submit" >Log in</button>
                  </div>
                </form>
              </div>
          </div>
        );
      }
    }
}