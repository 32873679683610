import React, { Component } from 'react'
import {Container } from 'react-bootstrap';
export default class Footer extends Component {
    render() {
        return (
            <div className="footer bg-dark">
                <Container>
                <span className="d-inline p-2 bg-dark text-white">spctek</span>
                </Container>
            </div>
            
        )
    }
}
