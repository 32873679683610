import { authHeader, handleResponse } from '../helpers';
export const algoPixService = {
    downloadCsvForAlopix,
    uploadAlgopixDatafromCsv
};
async function downloadCsvForAlopix(ApiCallSessionID) {
    const requestOptions = { 
        method: 'GET', 
        headers: authHeader()
    };
    try {
        const response = await fetch(process.env.REACT_APP_API + "/Algopix/downloadPartsForAlgo?ApiSessionID=" + ApiCallSessionID, requestOptions);
        if (!response.ok) {
            throw new Error('Failed to download file');
        }
        
        const blob = await response.blob();
        return blob;
    } 
    catch (error) 
    {
      throw new Error('Error downloading file: ' + error.message);
    }
}

function uploadAlgopixDatafromCsv(csvFile, ApiSessionID) {
    const requestOptions = { 
        method: 'POST', 
        //headers: { 'Content-Type': 'application/json' },
        body: csvFile
    };
    return fetch(process.env.REACT_APP_API +"/Algopix/UploadAlgopixDatafromCsv?ApiSessionID=" + ApiSessionID, requestOptions)
    .then(handleResponse);
}