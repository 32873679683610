import React, { Component } from 'react'
import { Container, Card} from 'react-bootstrap'
import { MainMenu } from './MainMenu';
import Footer from './Footer.js';
export default class Home extends Component{
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        
    }
    render(){
        return(
            <>
            <MainMenu/>
            <div className="fullScreen">
                <Container>
                    <Card border="secondary" style={{ width: '100%',margin:'10px 0px 0px 0px' }}>
                        <Card.Header>Announcement</Card.Header>
                        <Card.Body>
                            <Card.Title>Card Title</Card.Title>
                            <Card.Text>
                            Some quick example text to build on the card title and make up the bulk of
                            the card's content.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Container>
            </div>
            <Footer />
            </>
        )
    }
}