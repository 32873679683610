import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Login from './components/Login.js';
import  Home  from './components/Home';
import ResearchData from './components/ResearchData.js';
import ListingLists from './components/ListingLists.js';
import PastSearches from './components/PastSearches.js';
function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/Login" element={<Login/>} />
      <Route path="/Home" element={<Home/>} />
      <Route path="/ResearchData" element={<ResearchData/>} />
      <Route path='/LisingList/:TabsSummaryID' element={<ListingLists/>}/>
      <Route path="/" element={<Navigate replace to="/Login" />} />
      <Route path="/PastSearches/" element={<PastSearches/>} />
    </Routes>
    </BrowserRouter>
);
}
export default App;