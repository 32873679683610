import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { authenticationService } from '../services';
import { history, EmpRights } from '../helpers';
export class MainMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: authenticationService.currentUserValue,
            CanViewTeraPeak: false
        };
    }
    componentDidMount() {
        authenticationService.currentUser.subscribe(x => this.setState({
            currentUser: x,
            CanViewTeraPeak: x && x.rights.includes(EmpRights.CanViewTeraPeak),
            /*ViewAllEmployeesList: x && x.rights.includes(EmpRights.ViewAllEmployeesList),
            ViewEmpMenu: x && (
                    x.rights.includes(EmpRights.ViewAllEmployeesList) 
                    || x.rights.includes(EmpRights.AddUpdateEmployee)
            ),
            
            ViewAddendanceShift: x && x.rights.includes(EmpRights.ViewAddendanceShift),
            ViewAttMenu: x && (
                x.rights.includes(EmpRights.ViewMyAttendance)
            ),
            ViewMyAttendance: x && x.rights.includes(EmpRights.ViewMyAttendance),
            ViewAdminMenu: x && (
                x.rights.includes(EmpRights.ViewDepartmentSetup) 
                || x.rights.includes(EmpRights.ViewNationalitySetup) 
                || x.rights.includes(EmpRights.ViewDesignationSetup)
                || x.rights.includes(EmpRights.ViewMaritalStatusSetup)
                || x.rights.includes(EmpRights.ViewReligionSetup)
                || x.rights.includes(EmpRights.ViewEmploymentSetup)
                || x.rights.includes(EmpRights.ViewAddendanceShift)
            ),*/
            loggedInUser: JSON.parse(localStorage.getItem("currentUser")).fullName
           
        }));
        
    }
    
    logout() {
        authenticationService.logout();
        history.push('/');
    }
    render() {
        return (
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                <Container>
                    <Nav>
                        <NavLink className="Samelinetextnav p-2 bg-dark text-white" to="/Home">Home</NavLink>
                    </Nav>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        {/* this.state.CanViewTeraPeak && <Nav>
                                <NavDropdown id="nav-dropdown-dark-example" title="Items" menuVariant="dark">
                                {this.state.CanViewTeraPeak && <NavLink className="Samelinetextnav" to="/ResearchData">Research Data</NavLink>}
                                </NavDropdown>
                            </Nav> */}
                        {this.state.CanViewTeraPeak && <NavLink className="Samelinetextnav p-2 bg-dark text-white" to="/ResearchData">Research</NavLink>}
                        {this.state.CanViewTeraPeak && <NavLink className="Samelinetextnav p-2 bg-dark text-white" to="/PastSearches">Past Searches</NavLink>}
                    </Nav>
                        <Nav>
                            <NavDropdown id="nav-dropdown-dark-example" title={this.state.loggedInUser} menuVariant="dark">
                                <NavLink className="Samelinetextnav text-white align-right" to="/">Logout</NavLink>  
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        )
    }
}