import React from 'react';
import { Spinner } from 'react-bootstrap';

export default function Loader ({ message = "Loading..." }) {
    return (
        <div className="loader-overlay">
            <div className="loader">
                <table>
                    <tr>
                        <td>
                            <Spinner animation="border" role="status" />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span className="loader-message">{message}</span>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    );
};