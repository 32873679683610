import React, { useEffect, useState } from 'react';
import { itemsService } from '../services';
import Loader from './Loader';
import { Form } from 'react-bootstrap';
import MaterialTable from '@material-table/core';
import { tableIcons, constants } from '../helpers';
import { ExportCsv, ExportPdf } from '@material-table/exporters';
import '../App.css';
import { MainMenu } from './MainMenu';
const exportFileName = "Past Searches".concat(" ", new Date().getDate(), new Date().getMonth());
export default function PastSearches({ teraPeakTabsSummaryID, partName }) {
    const [pageAtiveData, setActiveData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        setIsLoading(true);
        itemsService.getPastSearches()
          .then(data => {
            setActiveData(data);
            setIsLoading(false);
          });
      }, []);

      const generateColumns = (data) => {
        if (data.length === 0) {
            return [];
        }
        const keys = Object.keys(data[0]);
        const filteredKeys = keys.filter(key => !key.startsWith('hd_'));
        return filteredKeys.map((key) => ({
            title: key,
            field: key,
            hidden: key === 'ApiCallSessionID',
            render: rowData => {
                if (key === 'Image') {
                    return <img src={rowData[key]} alt="Thumbnail" style={{ width: 100, height: 100 }} />;
                }
                else if(key === 'Search Date') {
                    const url = '/ResearchData?psid=' + rowData['ApiCallSessionID'];
                    return <a href={url} target='_blank' rel='noreferrer'>{rowData[key].toLocaleString()}</a>;
                }
                else {
                    return rowData[key];
                }
            }
        }));
    };
  return (
    <div>
    {isLoading? <Loader /> : 
    <Form>
        <MainMenu />
        <h1 className="text-center" id="pageHeading">Past Searches</h1>
       <MaterialTable
                columns={generateColumns(pageAtiveData)} // Dynamic columns
                data={pageAtiveData}
                icons={tableIcons}
                options={{
                    showTitle: false,
                    pageSizeOptions: constants.gridPageSizeOptions,
                    pageSize: constants.gridPageSize,
                    emptyRowsWhenPaging: false,
                    paginationType: 'normal',
                    showFirstLastPageButtons: false,
                    sorting: true,
                    search: true,
                    headerStyle: {
                        backgroundColor: '#ff4500',
                        color: '#FFF',
                        whiteSpace: 'nowrap',
                        position: 'sticky',
                        top: 0,
                        fontSize: '12px',
                        zIndex: 1
                    },
                    rowStyle:{
                        fontSize: '12px'
                    },
                    maxBodyHeight: '400px',
                    exportMenu: [
                        {
                            label: 'Export PDF',
                            exportFunc: (cols, datas) => ExportPdf(cols, datas, exportFileName)
                        }, {
                            label: 'Export CSV',
                            exportFunc: (cols, datas) => ExportCsv(cols, datas, exportFileName)
                        }]
                }}/>
     </Form>
    }
    </div>)
}